<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="taskName" label="任务名称" />
      <el-table-column prop="programName" label="程序名称" />
      <el-table-column prop="robotSN" label="机器人编码" />
      <el-table-column label="文件名称" prop="decodeFileName" >
        <template #default="scope">
          <a @click="exportReportData(scope.row)" style="color: #409eff;cursor: pointer;">{{ scope.row.decodeFileName }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="uploadTime" label="上传时间" />
    </Table>
  </div>
</template>

<script>
import { request } from '@/utils/request'
import { downloadFile } from "@/utils/request";
import { common } from '../../common/mixin'
import Table from '@/components/table/index'
import Filter from '@/components/table/filter'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data () {
    const route = useRoute()
    const taskName = route.query.taskName
    const robotSN = route.query.robotSN
    return {
      params: [
        {
          key: 'taskName',
          value: taskName,
          label: '任务名称'
        },
        {
          key: 'programName',
          value: '',
          label: '程序名称'
        },
        {
          key: 'robotSN',
          value: robotSN,
          label: '机器人编码'
        },
        {
          type: 'Date',
          key: 'timeRange',
          value: taskName == undefined ? [
            dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
            dayjs().format('YYYY-MM-DD') + ' 23:59:59'
          ] : [],
          label: '上传时间',
          options: {
            type: 'datetimerange',
            format: 'YYYY-MM-DD HH:mm:ss',
            'value-format': 'YYYY-MM-DD HH:mm:ss',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
          }
        }
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: []
    }
  },
  created () {},
  mounted () {
    this.$refs.filterRef?.searchData()
  },
  methods: {
    getData () {
      console.log(this.query)
      request('getReportList', {
        ...this.query,
        page: this.page,
        size: this.size,
      })
        .then(data => {
          this.tableDataList = data.records
          this.total = parseInt(data.total)
        })
        .catch(error => {
          console.error(error)
        })
    },
    getDataMap () {
      try {
        request('getArea', {
          parentId: 1
        }).then(data => {
          this.provinceList = data
        })
        request('getRobotStatusMap').then(data => {
          this.robotStatusList = data
        })
        request('getIndustryList').then(data => {
          this.industryList = data
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchData (data) {
      this.query = data
      this.getData()
    },
    updatePage (page, size) {
      this.page = page || 1
      this.size = size || 10
      this.getData()
    },
    handleClick (row) {
      this.$router.push({
        path: '/collect/healthScoreTrend',
        query: { robotSn: row.robotSn }
      })
    },
    // 下载报文  
    async exportReportData (row) {
      try {
        const res = await downloadFile("collectReportDownload", {}, {
          method: 'GET',
          params: { id: row.id }
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = row.decodeFileName
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.progress-text {
  font-size: 12px;
}
</style>
